import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';

export type AlertData = {
    say: string;
    timeout: number;
    severity: "error" | "warning" | "info" | "success";
    open:boolean;
}

export const INITIAL_ALERT:AlertData = {
    open:false,
    say:'Demo',
    severity:"info",
    timeout:6000
}

export default AlertData;

export function Toast(prop: AlertData) {

    const alertData = prop;

    const [open, setOpen] = React.useState(prop.open);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    return (
        <div>
            {console.log(prop)}
            <Snackbar open={open} autoHideDuration={alertData.timeout} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertData.severity}>
                    {alertData.say}
                </Alert>
            </Snackbar>
        </div>
    );
}