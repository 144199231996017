import React from 'react';
import { useTranslation } from 'react-i18next';

export const Home = () => {
    const { t, i18n } = useTranslation();    
    return (
        <div>{t("home")}</div>
    );
}

export default Home;