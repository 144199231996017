import React from 'react';
import { AddAssetForm } from './components/AddAssetForm';
import { Container, Main } from './views/MainLayout';
import { Route, Switch } from 'react-router-dom';
import SearchResult from './components/SearchResult';
import Header from './components/Header';
import Home from './components/Home';
import {useTranslation} from 'react-i18next';

function App() {
  
  const {t,i18n} = useTranslation();

  return (
    <Container>
      <Main>
        <div>
          <Header />
          <Switch>
            <Route exact from="/add" render={() => <AddAssetForm />} />
            <Route exact from="/search" render={() => <SearchResult />} />
            <Route exact from="/" render={()=><Home/>}/>
            {/* <AddAssetForm /> */}
          </Switch>
        </div>
      </Main>
    </Container>
  );
}

export default App;