import React from 'react';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import HomeIcon from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/Add';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Link } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { searchTermAtom } from '../state/state'
import { useRecoilState } from 'recoil';
import Select from '@material-ui/core/Select';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '12ch',
                '&:focus': {
                    width: '20ch',
                },
            },
        },
    }),
);
/*
export interface MyComponentProps extends RouteComponentProps<any> {
    propA: String;
    propB: Number;
}
*/

const Header = (props: RouteComponentProps<any>) => {

    const { t, i18n } = useTranslation();

    const classes = useStyles();

    const [auth, setAuth] = React.useState(true);

    const [language, setLanguage] = React.useState('en');

    const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClick = (pageURL: string) => {
        props.history.push(pageURL)
        setAnchorEl(null);
    };

    const handleLanguageChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setLanguage(e.target.value as string);
        i18n.changeLanguage(e.target.value as string);
    }

    // Check if ENTER is pressed in Search box and direct to Search result
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && searchTerm.searchTerm !== "") {
            e.preventDefault();
            props.history.push("/search");
            setAnchorEl(null);
        }
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleMenu}>
                        <MenuIcon />
                    </IconButton>
                    <Link variant="h6" className={classes.title} onClick={() => handleClick("/")} color="inherit">{t("serviceAsset")}</Link>
                    {auth && (
                        <>
                            <span>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={() => setAnchorEl(null)}
                                >
                                    <MenuItem onClick={() => handleClick("/")}><HomeIcon fontSize="small" />{t("home")}</MenuItem>
                                    <MenuItem onClick={() => handleClick("/add")}><AddIcon fontSize="small" />{t("addAsset")}</MenuItem>
                                    <MenuItem onClick={() => handleClick("/search")}><SearchIcon fontSize="small" />{t("searchJob")}</MenuItem>
                                </Menu>
                            </span>
                            {/* <span>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={language}
                                    onChange={handleLanguageChange}
                                    label="Language"
                                >
                                    <MenuItem value='da'>Danish</MenuItem>
                                    <MenuItem value='en'>English</MenuItem>
                                    <MenuItem value='fr'>French</MenuItem>
                                    <MenuItem value='it'>Italien</MenuItem>
                                    <MenuItem value='se'>Swedish</MenuItem>
                                    
                                </Select>
                            </span> */}
                            <span className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    type="search"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                                        setSearchTerm({ searchTerm: e.target.value })}
                                    onKeyDown={handleKeyDown}
                                    placeholder={t("search")}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </span>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default withRouter(Header);