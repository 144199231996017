import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    /* min-height: 100vh; */
    /* min-width: 100vw; */
    height:auto;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

    grid-template-columns: auto;
    grid-template-areas: "main";
`;

export const Main = styled.main`
    grid-area: main;
    padding: 5px;
`;

