import {atom} from 'recoil'

export interface SearchTerm {
    searchTerm:string
}

export const searchTermAtom = atom<SearchTerm>(
    {
        key: "inputData",
        default: {
            searchTerm:""
        }
    }
)