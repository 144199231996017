import { CellParams, ColDef, FilterModel, LinkOperator, RowsProp, XGrid } from '@material-ui/x-grid';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { API_BASE_URL } from '../api/config.json'
import { useRecoilValue } from 'recoil'
import { searchTermAtom } from '../state/state';
import { Alert } from '@material-ui/lab';
import { INITIAL_ALERT } from '../components/Toast';
import { Snackbar, Toolbar } from '@material-ui/core';
import { LicenseInfo } from '@material-ui/x-grid';
import { SYNCHROTEAM_BASE_URL } from '../config.json';
import { useTranslation } from 'react-i18next';

LicenseInfo.setLicenseKey('79823a405764ee8819cefb29a8a9f1f2T1JERVI6MjEwMTgsRVhQSVJZPTE2NDQxNDk2NDEwMDAsS0VZVkVSU0lPTj0x');

const filterModel: FilterModel = {
  items: [
    //  { columnField: 'status', operatorValue: 'equals', value: 'scheduled' },
    //  { columnField: 'status', operatorValue: 'equals', value: 'synchronized' },
  ],
  linkOperator: LinkOperator.Or,
};



const SearchResult = () => {
  const { t, i18n } = useTranslation();

  const columns: ColDef[] = [
    { field: 'id', headerName: t("job"), width: 80 },
    { field: 'customerName', headerName: t("customer"), width: 150 },
    { field: 'customerAddress', headerName: 'Adresse', width: 150 },
    { field: 'additionalAddress', headerName: 'Afdeling', width: 150 },
    { field: 'location', headerName: t("location"), width: 150 },
    { field: 'assetID', headerName: t("assetId"), width: 100 },
    { field: 'serial', headerName: t("serialNumberShort"), width: 150 },
    { field: 'status', headerName: t("status"), width: 100 },
    { field: 'scheduledStart', headerName: t("planned"), width: 150 },
    { field: 'jobId', hide: true }
  ];

  const [rows, setRows] = useState<RowsProp>([]);
  const input = useRecoilValue(searchTermAtom);
  const [alertData, setAlertData] = useState(INITIAL_ALERT);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertData(prevstate => ({ ...prevstate, open: false }));
  };

  const handleCellClick = (param: CellParams) => {
    // console.log(param.getValue("jobId"));
    window.open(`${SYNCHROTEAM_BASE_URL}Jobs/Details/${param.getValue("jobId")}`,"_synchroteam");
  }

  useEffect(() => {
    (async () => {
      if (input.searchTerm !== "") {
        try {
          const response = await fetch(`${API_BASE_URL}/api/search/${input.searchTerm}`);
          const data = await response.json();
          setRows(data);
          console.log(data);
        }
        catch (err) {
          setAlertData(prevState => ({ ...prevState, open: true, say: `Der er sket en fejl ved hentning af data ${err}`, severity: "error", timeout: 6000 }));
        }
      }
    })()
  }, [input.searchTerm])

  return (
    <div style={{ height: 500, width: '100%' }}>
      <XGrid
        hideFooterSelectedRowCount={true}
        pagination
        pageSize={25}
        onCellClick={handleCellClick}
        autoHeight rows={rows}
        columns={columns}
        filterModel={filterModel} />

      <Snackbar open={alertData.open} autoHideDuration={alertData.timeout} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertData.severity}>
          {alertData.say}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SearchResult